import { OwnUpSubheadlineMedium } from '@rategravity/own-up-component-library-new';
import React from 'react';
import { PostSnippet } from '../../types';
import { ArticlesList } from '../articles-list';
import { PageTitleContainer } from '../page-title-container';
import { PaginationContainer } from '../pagination';

export interface CollectionByCategory {
  tag: string;
  numPages: number;
  page: number;
  relatedPosts: PostSnippet[];
}

export const CollectionByCategory = ({
  relatedPosts,
  tag,
  numPages,
  page
}: CollectionByCategory) => (
  <React.Fragment>
    <PageTitleContainer>
      <OwnUpSubheadlineMedium variant="subtitle1">{tag}</OwnUpSubheadlineMedium>
    </PageTitleContainer>
    <ArticlesList posts={relatedPosts} context="normal" />
    <PaginationContainer numPages={numPages} page={page} tag={tag} />
  </React.Fragment>
);
