import { graphql } from 'gatsby';
import React from 'react';
import { BackToHomepage } from '../../components/back-to-homepage';
import { CollectionByCategory } from '../../components/collection-by-category';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { mapPostData } from '../../module/map-post-data';
import { Post, PostSnippet } from '../../types';

export const pageQuery = graphql`
  query BlogPostsWithTag($tag: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          tags
          imgAlt
          publishedDate
        }
      }
    }
  }
`;

interface QueryData {
  data: {
    allMarkdownRemark: {
      nodes: Post[];
    };
  };
  pageContext: {
    skip: number;
    numPages: number;
    currentPage: number;
    tag: string;
  };
}

export const Page = ({ data, pageContext }: QueryData) => {
  const relatedPost: PostSnippet[] = data.allMarkdownRemark.nodes.map(mapPostData);

  const { numPages, currentPage, tag } = pageContext;

  return (
    <>
      {process.env.STORYBOOK_ENV === 'true' ? null : (
        <SEO
          title={pageContext.tag}
          description={`Own Up blog articles related to ${pageContext.tag}`}
        />
      )}
      <Layout>
        <BackToHomepage />
        <CollectionByCategory
          tag={tag}
          relatedPosts={relatedPost}
          numPages={numPages}
          page={currentPage}
        />
      </Layout>
    </>
  );
};

//eslint-disable-next-line import/no-default-export
export default Page;
