import {
  OwnUpFillButtonPrimary,
  PropsWithTheme,
  WALDEN_100,
  WHITE_100
} from '@rategravity/own-up-component-library-new';
import styled from 'styled-components';

export const PaginationContainerDesktop = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const PaginationContainerMobile = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const Navigation = styled.nav`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 8)};
  display: flex;
  justify-content: center;
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    margin-left: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  }
`;

export const LeftArrow = styled(OwnUpFillButtonPrimary)``;

export const RightArrow = styled(OwnUpFillButtonPrimary)``;

export const NumberButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumberButton = styled.button`
  width: ${({ theme }: PropsWithTheme<{ current: number; number: number }>) =>
    `${theme.spacing(6)}px`};
  height: ${({ theme }: PropsWithTheme<{ current: number; number: number }>) =>
    `${theme.spacing(6)}px`};
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: ${({ current, number }: { current: number; number: number }) =>
    current === number ? WALDEN_100 : 'transparent'};
  color: ${({ current, number }: { current: number; number: number }) =>
    current === number ? WHITE_100 : WALDEN_100};
`;
