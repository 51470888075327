import { OwnUpGridWrapper } from '@rategravity/own-up-component-library-new';
import { ArrowLeftIcon } from '@rategravity/own-up-component-library-new/icon-library/system-icons/standard-icons/arrow-left';
import { ArrowRightIcon } from '@rategravity/own-up-component-library-new/icon-library/system-icons/standard-icons/arrow-right';
import { navigate } from 'gatsby';
import React from 'react';
import { kebab } from '../../module/kebab';
import {
  LeftArrow,
  List,
  Navigation,
  NumberButton,
  NumberButtonsContainer,
  PaginationContainerDesktop,
  PaginationContainerMobile,
  RightArrow
} from './components';

interface PaginationProps {
  numPages: number;
  page: number;
  tag?: string;
}

export const Pagination = ({ numPages, page, limit, tag }: PaginationProps & { limit: number }) => {
  // center point of page numbers in nav.
  const center = limit / 2 + 0.5;

  // determine the starting point of the nav.
  // 1. Check if current page is at or below the center point on the first batch of pages displayed in the nav.
  // If so, starting point will just be 1.
  // 2. Check if the current page is on the last batch of pages displayed in the nav and if it is above the center point of the nav.
  // If so, offset is the full length of the nav (indicated by `limit`).
  // 3. In all other cases, offset is half the length of the nav (indicated by `center`).
  let navStart: number;
  if (page <= center) {
    navStart = 1;
  } else {
    navStart =
      page > numPages - center ? Math.max(numPages - limit + 1, 1) : Math.round(page - center + 1);
  }
  const pageNumbers = [];

  // add page numbers to the nav from starting point until the limit is reached
  do {
    pageNumbers.push(navStart);
    navStart = navStart + 1;
  } while (pageNumbers.length < limit && navStart <= numPages);

  const setPagination = (direction: string, num?: number) => {
    const prefix = tag ? `/tag/${kebab(tag)}` : '';

    switch (direction) {
      case 'page up':
        if (typeof window !== 'undefined') {
          void navigate(`${prefix}/page/${page + 1}/`);
        }
        break;
      case 'page down':
        const downDestination = page - 1 === 1 && tag ? prefix : `${prefix}/page/${page - 1}/`;
        if (typeof window !== 'undefined') {
          void navigate(downDestination);
        }
        break;
      case 'set page':
        const setDestination = num === 1 && tag ? prefix : `${prefix}/page/${num}/`;
        if (typeof window !== 'undefined') {
          void navigate(setDestination);
        }
    }
  };

  return (
    <OwnUpGridWrapper>
      <Navigation>
        <List>
          <LeftArrow
            onClick={() => {
              setPagination('page down');
            }}
            icon={<ArrowLeftIcon />}
            disabled={page === 1}
            aria-label="left arrow"
          />
          <NumberButtonsContainer>
            {pageNumbers.map((number) => (
              <NumberButton
                key={number}
                number={number}
                current={page}
                onClick={() => {
                  setPagination('set page', number);
                }}
                aria-current={page === number ? 'page' : undefined}
              >
                {number}
              </NumberButton>
            ))}
          </NumberButtonsContainer>

          <RightArrow
            onClick={() => {
              setPagination('page up');
            }}
            icon={<ArrowRightIcon />}
            disabled={page === numPages}
            aria-label="right arrow"
          />
        </List>
      </Navigation>
    </OwnUpGridWrapper>
  );
};

export const PaginationContainer = (props: PaginationProps) => (
  <React.Fragment>
    <PaginationContainerDesktop>
      <Pagination limit={10} {...props} />
    </PaginationContainerDesktop>
    <PaginationContainerMobile>
      <Pagination limit={3} {...props} />
    </PaginationContainerMobile>
  </React.Fragment>
);
